import React from "react";
import { addFavorite, removeFavorite } from "../actions";

class MovieCard extends React.Component {
    handleFavoriteClick = () => {
        const { movie } = this.props;
        this.props.dispatch(addFavorite(movie));
    }
    handleunFavoriteClick = () => {
        const { movie } = this.props;
        this.props.dispatch(removeFavorite(movie));
    }
    render() {
        const { movie, isFavorite } = this.props;
        return (
            <div className="movie-card">
                <div className="left">
                    <img alt="movie-poster" src={movie.Poster} />
                </div>
                <div className="right">
                    <div className="title"><span>{movie.Title}</span>  <span>{movie.Year}</span></div>
                    <div className="plot">{movie.Plot}</div>
                    <br/>
                    <div className="Genre">
                            <figure>
                                <span className="released"><img src="https://cdn-icons-gif.flaticon.com/17204/17204039.gif" alt="released"/></span>
                                <figcaption>{movie.Released}</figcaption>
                            </figure>
                            <figure>
                                <span className="boxOffice"><img src="https://cdn-icons-gif.flaticon.com/13971/13971366.gif" alt="boxOffice"/></span>
                                <figcaption>{movie.BoxOffice}</figcaption>
                            </figure>
                            <figure>
                                <span className="rating"><img src="https://cdn-icons-gif.flaticon.com/16104/16104331.gif" alt="rating"/></span>
                                <figcaption>{movie.imdbRating}</figcaption>
                            </figure>
                            <figure>
                                <span className="votes"><img src="https://cdn-icons-gif.flaticon.com/12417/12417452.gif" alt="votes"/></span>
                                <figcaption>{movie.imdbVotes}</figcaption>
                            </figure>
                    </div>
                    <div className="footer">
                        {
                            isFavorite
                                ? <button className="unfavourite-btn" onClick={this.handleunFavoriteClick}>Unfavourite</button> 
                                :<button className="favourite-btn" onClick={this.handleFavoriteClick}>Favourite</button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default MovieCard;
