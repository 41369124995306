import React from "react";
import { connect } from "react-redux";
// import { data } from '../data';
import { 
    addMovieToList, 
    handleMovieSearch
} from '../actions';

// import { connect } from "../";
class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: ""
        };
    }

    handleAddToMovies = (movie) => {
        this.props.dispatch(addMovieToList(movie));
        this.setState({
            showSearchResults: false
        });
    };

    handleSearch = () => {
        const { searchText } = this.state;
        this.props.dispatch(handleMovieSearch(searchText));
        // dispatch(searchApi(searchText));
    };

    handleChange = (e) => {
        this.setState({
            searchText: e.target.value
        })
    }

    render() {
        const { result:movie, showSearchResults } = this.props.search;
        return (
            <div className="nav">
                <div className="search-container">
                    <input placeholder="Search ..." onChange={this.handleChange} />
                    <button id="search-btn" onClick={this.handleSearch}>Search</button>
                    {
                        showSearchResults &&
                        // <div className="search-results">
                        //     <div className="search-result">
                        //         <img src={movie.Poster} alt="search-pic" />
                        //         <div className="movie-info">
                        //             <span>{movie.Title}</span>
                        //             <button onClick={() => this.handleAddToMovies(movie)}>Add to Movies</button>
                        //         </div>
                        //     </div>
                        // </div>
                        <div className="movie-card search-results">
                        <div className="left">
                            <img alt="movie-poster" src={movie.Poster} />
                        </div>
                        <div className="right">
                            <div className="title"><span>{movie.Title}</span>  <span>{movie.Year}</span></div>
                            <div className="plot">{movie.Plot}</div>
                            <br/>
                            <div className="Genre">
                                    <figure>
                                        <span className="released"><img src="https://cdn-icons-gif.flaticon.com/17204/17204039.gif" alt="released"/></span>
                                        <figcaption>{movie.Released}</figcaption>
                                    </figure>
                                    <figure>
                                        <span className="boxOffice"><img src="https://cdn-icons-gif.flaticon.com/13971/13971366.gif" alt="boxOffice"/></span>
                                        <figcaption>{movie.BoxOffice}</figcaption>
                                    </figure>
                                    <figure>
                                        <span className="rating"><img src="https://cdn-icons-gif.flaticon.com/16104/16104331.gif" alt="rating"/></span>
                                        <figcaption>{movie.imdbRating}</figcaption>
                                    </figure>
                            </div>
                            <div className="footer">
                                {/* {
                                    isFavorite
                                        ? <button className="unfavourite-btn" onClick={this.handleunFavoriteClick}>Unfavourite</button> 
                                        :<button className="favourite-btn" onClick={this.handleFavoriteClick}>Favourite</button>
                                } */}
                                <button className="favourite-btn" onClick={() => this.handleAddToMovies(movie)}>Add</button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

// class NavbarWrapper extends React.Component {
//     render() {
//         return (
//             <StoreContext.Consumer>
//                 {(store) => <Navbar dispatch={store.dispatch} search={this.props.search} />}
//             </StoreContext.Consumer>
//         );
//     }
// }

const mapStoreToProps = ({search}) => {
    return {
        search: search
    }
}

const NavbarWrapper = connect(mapStoreToProps)(Navbar);

export default NavbarWrapper;
