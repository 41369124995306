import React from "react";
import { connect } from "react-redux";

import { data } from "../data";
import { Navbar, MovieCard } from "./";
import { addMovies, setShowFavorites } from "../actions";
// import { connect } from "../index";


class App extends React.Component {

  componentDidMount() {
    this.props.dispatch(addMovies(data))
    console.log("State", this.props)
  }
  isMovieFavorite = (movie) => {
    const { movies } = this.props;
    const index = movies.favorites.indexOf(movie);
    if (index !== -1) {
      return true;
    }
    return false;
  }
  onChangeTab = (val) => {
    this.props.dispatch(setShowFavorites(val))
  }

  render() {
    // document.addEventListener('contextmenu', event => event.preventDefault());
    const {movies, search} = this.props;
    const { list, favorites, showFavourites } = movies;
    console.log("RENDER", this.props);

    const displayMovies = showFavourites ? favorites : list;
    return (
      <div className="App">
        <Navbar
          search={search}
        />
        <div className="main">
          <div className="tabs">
            <div className={`tab ${showFavourites ? '': 'active-tabs'}`} onClick={() => this.onChangeTab(false)}>Movies</div>
            <div className={`tab ${showFavourites ? 'active-tabs': ''}`} onClick={() => this.onChangeTab(true)}>Favorites</div>
          </div>

          <div className="list">
            {displayMovies.map((movie, index) => (
              <MovieCard
                movie={movie}
                key={`movies-${index}`}
                dispatch={this.props.dispatch}
                isFavorite={this.isMovieFavorite(movie)}
              />
            ))}
          </div>
          {displayMovies.length === 0 ? <div className="no-movies">No movies to display!</div> : null}
        </div>
      </div>
    );
  }
}

// class AppWrapper extends React.Component {
//   render() {
//     return (
//       <StoreContext.Consumer>
//         {(store) => <App store={store} />}
//       </StoreContext.Consumer>
//     )
//   }
// }

function mapStateToProps(state){
  return{
    movies:state.movies,
    search:state.search
  }
}

const connectedAppComponent = connect(mapStateToProps)(App);

export default connectedAppComponent;
