/* eslint-disable no-lone-blocks */
// {
//     type: 'ADD_MOVIES';
//     movies: [m1, m2, m3]
// }

// action types
export const ADD_MOVIES = 'ADD_MOVIES';
export const ADD_FAVORITE = 'ADD_FAVORITE';
export const REMOVE_FROM_FAVORITES = 'REMOVE_FROM_FAVORITES';
export const SET_SHOW_FAVORITES = 'SET_SHOW_FAVORITES';
export const ADD_MOVIE_TO_LIST = 'ADD_MOVIE_TO_LIST';
export const ADD_SEARCH_RESULT = 'ADD_SEARCH_RESULT';

// action creators
export function addMovies(movies) {
    return {
        type: ADD_MOVIES,
        movies: movies
    }
}

export function addFavorite(movie) {
    return {
        type: ADD_FAVORITE,
        movie: movie
    }
}

export function removeFavorite(movie) {
    return {
        type: REMOVE_FROM_FAVORITES,
        movie: movie
    }
}

export function setShowFavorites(val) {
    return {
        type: SET_SHOW_FAVORITES,
        val: val
    }
}

export function addMovieToList(movie) {
    return {
        type: ADD_MOVIE_TO_LIST,
        movie: movie
    }
}

export function handleMovieSearch(movie) {
    const url = `http://www.omdbapi.com/?apikey=1851726d&t=${movie}`;
    return async function (dispatch) {
        await fetch(url)
            .then(response => response.json())
            .then(movie => {
                console.log('movie', movie);
                // dispatch an action
                dispatch(addMovieSearchResult(movie));
            });
    }
}

export function addMovieSearchResult(movie) {
    return {
        type: ADD_SEARCH_RESULT,
        movie: movie
    }
}
